<template>
  <el-dialog title="详情"
    :visible.sync="dialogVisible"
    custom-class="policy-import-dialog"
    width="530px"
    :before-close="handleClose">
    <div>
      <div class="cell-box" v-for="item,index in cellList" :key="index">
        <div class="cell-box-label">{{ item.label }}</div>
        <div class="cell-box-value">
          {{ detailsData[item.value] || '--' }}
        </div>
      </div>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="dialogVisible = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { downloadPolicyTemplate, policyArchiveParse } from "@/api/policy";
import { zeroPartsLogDetails } from "@/api/basicData";
export default {
  props: {
    showDialog: Boolean,
    editData: {
      type: Object,
      default: ()=> {}
    }
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          zeroPartsLogDetails({
            id: this.editData.id
          }).then(res=>{
            this.detailsData = res.data
          })
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      detailsData: {},
      cellList: [
        // {
        //   label: '标题',
        //   value: ''
        // },
        {
          label: 'id',
          value: 'id'
        },
        {
          label: 'admin_id',
          value: 'userId'
        },
        {
          label: '用户名',
          value: 'username'
        },
        {
          label: 'url',
          value: 'requestUrl'
        },
        {
          label: '所在地区',
          value: 'location'
        },
        {
          label: '查看页面',
          value: 'viewPage'
        },
        {
          label: '配件品号',
          value: 'partNumber'
        },
        {
          label: '配件名称',
          value: 'partName'
        },
        {
          label: '配件型号',
          value: 'partModel'
        },
        {
          label: '配件品牌',
          value: 'partBrand'
        },
        {
          label: '配件价格',
          value: 'partPrice'
        },
        {
          label: '查看时间',
          value: 'viewTime'
        },
      ]
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    handlePreview(e) {
      this.fileList = [e];
    },
    handleRemove() {
      this.fileList = [];
    },
    handleImport() {
      if (!this.fileList.length) {
        this.$message.error('请选择文件');
        return false;
      }
      let formData = new FormData();
      formData.append('file', this.fileList[0].raw);
      // formData.append('importZeroPartsReq', {
      //   userId: this.$store.state.userInfo.userId,
      //   userName: this.$store.state.userInfo.name
      // });
      formData.append("userId", this.$store.state.userInfo.userId);
      formData.append("userName",this.$store.state.userInfo.name);
      
      importZeroParts(formData).then(res => {
        this.$message.success('上传成功');
        this.dialogVisible = false;
        this.fileList = [];
        this.$emit('changeList');
      }).catch(err => {
        setTimeout(() => {
          this.$message.error('上传失败');
        }, 3000);
      });
      // this.dialogVisible = false;
    },
    handleDownloadTemplate() {
      downloadTemplate();
      // const link = document.createElement('a'); // 生成一个a标签。
      // link.href = '/resource/保单导入模板.xls';
      // link.click();
    }
  }
};
</script>

<style lang="scss">
.policy-import-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  
  .el-dialog__header {
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #fff;
    .el-dialog__title {
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 0;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  &-b {
    height: auto;
    padding: 10px 24px;
    &-img {
      display: block;
      width: 40px;
      height: 40px;
      margin: 14px auto 12px;
    }
    &-text {
      text-align: center;
      font-size: 18px;
      margin-bottom: 5px;
      color: #333333;
    }
    .upload-demo {
      width: 100%;
      .el-upload {
        width: 100%;
      }
      .el-upload-dragger {
        width: 100%;
        height: auto;
        border: none;
        background-color: transparent;
      }
    }
  }
  .cell-box {
    display: flex;
    align-items: center;
    margin-top: 24px;
    &-label {
      width: 96px;
      font-size: 14px;
      color: #999999;
      text-align: right;
    }
    &-value {
      flex: 1;
      font-size: 14px;
      color: #333333;
      margin-left: 12px;
    }
  }
}
</style>