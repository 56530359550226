<template>
  <div class="analysis-box">
    <div class="analysis-box-title flex-box flex-between flex-items">
      <span>工时分布</span>
      <!-- <el-select v-model="timeType" placeholder="请选择">
          <el-option
            v-for="item in timeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> -->
    </div>
    <div class="analysis-box-content flex-box">
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
export default {
  components: { Chart },
  props: {},
  data() {
    return {
      timeType: "",
      timeOptions: [
        {
          label: "本周",
          value: 1,
        },
      ],
      gaugeOptions: {},
      dataGather: {
        nonono: 2222
      }
    };
  },
  created() {
    this.$nextTick(() => {
      this.gaugeOptions = {};
    });
  },
};
</script>

<style lang="scss" scoped>
.analysis-box {
  padding: 16px 24px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 4px;
  &-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 24px;
  }
  &-title::before {
    content: "";
    position: absolute;
    left: -24px;
    top: 0;
    bottom: 0;
    width: 6px;
    background: #4278c9;
  }
  &-content {
    padding: 0 16px;
    overflow: auto;
  }
}
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-items {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
</style>